var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("SpaceDataList", {
    attrs: {
      spaceData: _vm.datasetSpaces,
      spaceType: _vm.spaceTypes.VENDOR_SPACE,
      spaceIcon: "mdi-database",
      headerTitle: "Datasets",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }